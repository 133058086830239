import React from 'react';
import style from './Financial.module.css';

function Financial() {
    return (
        <div className={style.financial}>
            <div className={style.financialWrapper}>
                <h2>Financial</h2>
                <div className={style.financialContent}>
                    <p>
                        <b>Total investment: 2,255,000.03 USD</b>
                        <br/>
                        <b>IRR: 10%</b>
                        <br/>
                        <b>Payback period: 3 year(s)</b>
                    </p>
                    <ul>
                        <li>Own funds<span>50,000 USD</span></li>
                        <li>Bank loans<span>9,000 USD</span></li>
                        <li>Investments attracted on the platform<span>2,196,000 USD</span></li>
                        <li>Preliminary payments<span>0.03 USD</span></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Financial;

import React from 'react';
import style from './BenefitsAndSocial.module.css';

function BenefitsAndSocial() {
    return (
        <div className={style.about}>
            <div className={style.aboutWrapper}>
                <h2>Project Benefits</h2>
                <p>
                    Residents can maintain their fitness regime in the state-of-the-art fitness center or take a
                    refreshing dip in the shimmering swimming pool. And for those who prefer to shop, the commercial
                    zone offers a wealth of retail opportunities, ensuring that residents have everything they need just
                    steps from their door.
                </p>
                <h2>Social efficiency</h2>
                <p>
                    At the heart of this stunning complex is an array of amenities that provide residents with
                    everything they need to live their best lives. Just a three-minute walk away is a private sandy
                    beach, where the crystal-clear waters of the Mediterranean Sea beckon residents to relax and unwind.
                    You can also take a five-minute stroll to the local supermarket to stock up on supplies or explore
                    the nearby town of Kireni, just a 25-minute drive away. And when it's time to jet off to your next
                    adventure, Ercan Airport is just 40 minutes away.
                </p>
                <p>
                    But the true jewel in the crown of ALOHA LIFE lies just across the road. Here, a five-star hotel
                    complete with a casino, bar, and restaurant awaits, along with an international marina boasting room
                    for 700 yachts. For those with a love of the sea, the marina offers endless opportunities for
                    sailing and exploration.
                </p>
            </div>
        </div>
    )
}

export default BenefitsAndSocial;

import React, {useState} from 'react';
import style from './ProjectWidget.module.css';
import {Link, useNavigate} from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


function ProjectWidget({projectId, bottom, right, backgroundColor, isModalOpen, tokensCount}) {
    const [widgetParams, setWidgetParams] = useState({
        projectId,
        bottom: bottom || '25px',
        right: right || '25px',
        backgroundColor: backgroundColor || 'radial-gradient(circle, rgba(0,114,181,1) 40%, rgba(12,68,112,0.95) 100%)',
        isModalOpen: isModalOpen || false,
        tokensCount: tokensCount || 10,
    });
    const [project, setProject] = useState({
        name: 'Aloha Life',
        totalTokens: 100000,
        availableTokens: 10000,
        exchangeRate: 5,
    });

    const toggleWidgetModal = (isModalOpen = true) => {
        setWidgetParams({...widgetParams, isModalOpen});
    };

    const renderWidgetComponents = () => {
        const {isModalOpen} = widgetParams;
        let component = null;

        if (!widgetParams.projectId) {
            console.error('ProjectWidget: projectId is not defined');
            return;
        }

        if (isModalOpen) {
            const {name, totalTokens, availableTokens, exchangeRate} = project;
            const progress = (availableTokens * 100) / totalTokens;

            component = (
                <div className={style.projectWidgetModalWrapper} onClick={() => toggleWidgetModal(false)}>
                    <div className={style.projectWidgetModal} onClick={(event) => event.stopPropagation()}>
                        <CloseIcon className={style.projectWidgetModalClose} onClick={() => toggleWidgetModal(false)}/>
                        <h2 className={style.projectWidgetModalTitle}>Buy m² project tokens</h2>

                        <div className={style.projectWidgetModalContent}>
                            {
                                availableTokens > 0 ? (
                                    <>
                                        <div className={style.projectWidgetModalInfo}>
                                            <div className={style.projectWidgetModalInfoFirst}>
                                                <ul>
                                                    <li>{`Project name: ${name}`}</li>
                                                    <li>{`Total tokens: ${totalTokens}`}</li>
                                                    <li>{`Available tokens: ${availableTokens}`}</li>
                                                </ul>
                                                <div style={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>
                                                    <div style={{width: 115, height: 115}}>
                                                        <CircularProgressbar
                                                            minValue={0}
                                                            maxValue={100}
                                                            value={progress}
                                                            text={`Sold ${progress}%`}
                                                            styles={buildStyles({
                                                                strokeLinecap: 'butt',
                                                                textSize: '16px',
                                                                transition: 'stroke-dashoffset 0.5s ease 0s',
                                                                transform: 'rotate(0.25turn)',
                                                                transformOrigin: 'center center',
                                                                pathTransitionDuration: 0.5,
                                                                trailColor: '#d6d6d6',
                                                                backgroundColor: '#3e98c7',
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={style.projectTokenRate}>
                                                {`1 m² token = ${exchangeRate} `}
                                                <span>M2C</span>
                                            </div>
                                        </div>
                                        <div className={style.projectWidgetModalActions}>
                                            <label htmlFor={'token_count'}>
                                                <span>Number of tokens</span>
                                                <input id={'token_count'} value={widgetParams.tokensCount}
                                                       className={style.requestFormInput}
                                                       onChange={(e) => setWidgetParams({
                                                           ...widgetParams,
                                                           tokensCount: parseInt(e.target.value) || 0
                                                       })}/>
                                            </label>
                                            <a
                                                href={`https://cabinet.grem.capital/new/projects/${widgetParams.projectId}/buy?tokensCount=${widgetParams.tokensCount}`}
                                                target={'_blank'}
                                                onClick={() => toggleWidgetModal(false)}
                                            >
                                                <button className={style.projectWidgetModalActionsButton}>
                                                    {`Buy ${widgetParams.tokensCount} m² project tokens for ${widgetParams.tokensCount * exchangeRate} M2C`}
                                                </button>
                                            </a>
                                        </div>
                                    </>
                                ) : (
                                    <div className={style.projectWidgetModalEndedWrapper}>
                                        <h3 className={style.projectWidgetModalEnded}>Token sale is over</h3>
                                        <Link to={'/projects'}>
                                            Show other projects
                                        </Link>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            );
        } else {
            component = (
                <div className={style.projectWidget} style={{
                    background: widgetParams.backgroundColor,
                    bottom: widgetParams.bottom,
                    right: widgetParams.right,
                }} onClick={toggleWidgetModal}>
                    Buy project tokens
                </div>
            );
        }

        return component;
    };

    return renderWidgetComponents();
}

export default ProjectWidget;

import React from 'react';
import style from './Footer.module.css';
import {Link} from 'react-router-dom';

//import bgImage from 'assets/images/bg0.jpg';

function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <footer className={style.footer}>
            <a href={'https://grem.capital/disclaimer'} target={'_blank'}>{`© GREM London LTD, Company number 14177574, 2017 - ${currentYear}`}</a>
        </footer>
    );
}

export default Footer;

import React from 'react';
import style from './Error.module.css';
import {Link} from 'react-router-dom';
import bgImage from 'assets/images/mainBg.jpg';

function Error({code = 404, title = 'Something went wrong...'}) {
    return (
        <div className={style.error} style={{
            background: `url(${bgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }}>
            <div className={style.errorWrapper}>
                <h1>Page not found</h1>
                <h3>{code}</h3>
                <p>{title}</p>
                <nav className={style.errorNav}>
                    <ul>
                        <li>
                            <a href={'/'} onClick={(e) => {
                                e.preventDefault();
                                window.history.back();
                            }}>
                                Back
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default Error;

import React from "react";
import {createRoot} from 'react-dom/client';
import "./index.css";
import "animate.css/animate.min.css";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {MainLayout} from './components/index';
import {Index, Cookies, Error} from "./pages";

process.env.CI = false;

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path="/" element={<MainLayout/>}>
                    <Route index element={<Index/>}/>
                    <Route path={'/'} index element={<Index/>}/>
                </Route>

                <Route path="*" element={<Error/>}/>
            </Routes>
        </Router>
    </React.StrictMode>
);

console.log('%c GREM agency', 'font-weight: bold; font-size: 72px;color: #00ffff; text-shadow: 3px 3px 0 #00ECEC, 6px 6px 0 #80FFFF, 9px 9px 0 #070649, 12px 12px 0 #070649, 15px 15px 0 #070649, 18px 18px 0 #070649, 21px 21px 0 #070649');

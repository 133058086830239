import React from 'react';
import style from './Button.module.css';

function Button({title = '', onClick = () => {}, styles = {}}) {
    return (
        <button
            className={style.button}
            style={styles}
            onClick={onClick}
        >
            {title}
        </button>
    );
}

export default Button;

import React, {useState} from 'react';
import {Slide} from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import style from './Slider.module.css';
import slide1 from 'assets/images/slider/slide1.png';
import slide2 from 'assets/images/slider/slide2.png';
import slide3 from 'assets/images/slider/slide3.png';
import slide4 from 'assets/images/slider/slide4.png';
import slide5 from 'assets/images/slider/slide5.jpg';
import slide6 from 'assets/images/slider/slide6.jpg';
import slide7 from 'assets/images/slider/slide7.jpg';
import slide8 from 'assets/images/slider/slide8.jpg';

const slideImages = [
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8
];

function Slider() {
    const [maxSliderWidth, setMaxSliderWidth] = useState(window.innerWidth);
    const properties = {
        duration: 5000,
        transitionDuration: 500,
        infinite: false,
        indicators: true,
        easing: "ease",
        prevArrow: <button className={style.sliderArrow}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff">
                <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/>
            </svg>
        </button>,
        nextArrow: <button className={style.sliderArrow}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff">
                <path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/>
            </svg>
        </button>
    }

    window.onresize = (event) => {
        setMaxSliderWidth(window.innerWidth);
    }

    return (
        <div className={style.slider}>
            <div className={style.sliderWrapper} style={{
                maxWidth: maxSliderWidth,
            }}>
                <Slide {...properties}>
                    {slideImages.map((each, index) => (
                        <div key={index} className={style.slide}>
                            <img className="lazy" src={each} alt="sample" />
                        </div>
                    ))}
                </Slide>
            </div>
        </div>
    )
}

export default Slider;

import React from 'react';
import style from './Concept.module.css';

function Concept() {
    return (
        <div className={style.about}>
            <div className={style.aboutWrapper}>
                <h2>Concept</h2>
                <p>
                    Nestled in the heart of Esentepe, one of the most picturesque areas of Cyprus, lies the exquisite
                    residential complex ALOHA LIFE. This stunning complex offers a luxurious lifestyle in a prime
                    location, making it an irresistible investment option for those seeking a place to live, rent, or
                    resell. There are 4 blocks of apartments (2 floors roof terrace). Thanks to the cascading
                    development area, all apartments will have a sea view.
                </p>
                <p>
                    Types and quantity of apartments:
                    <ul>
                        <li>Studio - from 55 sq.m. (8 units)</li>
                        <li>1 1Loft – from 68 sq.m (8 units)</li>
                        <li>2 1 – 102 sq.m.(8 unoits)</li>
                        <li>2 1Loft - 120 sq.m. (4 units)</li>
                        <li>3 1Loft – 132 sq.m. (4 units)</li>
                    </ul>
                </p>
                <p>
                    All apartments are with repair (for finishing), with built-in kitchen, built-in wardrobes and
                    finished sanitary conveniences.
                </p>
                <p>
                    Installment terms:
                    <ul>
                        <li>At the booking stage – 5000 pounds</li>
                        <li>Downpayment – 35%</li>
                        <li>Quarterly till the completion deadline – 45%</li>
                    </ul>
                    <br/>
                    After Completion deadline:
                    <ul>
                        <li>Q4 2025 – 5%</li>
                        <li>Q1 2026 – 5%</li>
                        <li>Q2 2026 - 5%</li>
                        <li>Q3 2026 - 5%</li>
                    </ul>
                </p>
                <p>
                    All in all, ALOHA LIFE offers an unparalleled lifestyle in an enviable location. With a range of
                    amenities and attractions just a stone's throw away, this stunning residential complex is the
                    perfect place to call home.
                </p>
            </div>
        </div>
    )
}

export default Concept;

import React from 'react';
import Main from './components/Main/Main';
import Concept from './components/Concept/Concept';
import Slider from './components/Slider/Slider';
import BenefitsAndSocial from "./components/BenefitsAndSocial/BenefitsAndSocial";
import ProjectWidget from "./components/ProjectWidget/ProjectWidget";
import Map from "./components/Map/Map";
import Financial from "./components/Financial/Financial";


function Brooklyn() {
    return (
        <div>
            <Main/>
            <Concept/>
            <Slider/>
            <BenefitsAndSocial/>
            <Map/>
            <Financial/>
            <ProjectWidget projectId={'64268347d96d29705f451ff6'}/>
        </div>
    );
}

export default Brooklyn;


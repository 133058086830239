import React from "react";
import style from './ErrorFallback.module.css';

function ErrorFallback({error, resetErrorBoundary}) {

    return (
        <div
            role={'alert'}
            className={style.errorBlock}
        >
{/*            <div className={style.errorWrapper}>
                <p>{globalDictionary.get('error_title', userLanguage)}</p>
                <button onClick={resetErrorBoundary}>{globalDictionary.get('error_try_again', userLanguage)}</button>
            </div>*/}
        </div>
    )
}

export default ErrorFallback;

import React, {useEffect, useState} from 'react';
import style from './Layout.module.css';
import {Waypoint} from 'react-waypoint';
import {Outlet} from 'react-router';
import Footer from './components/Footer/Footer';
import {CookieAlert, ScrollTop} from 'components/index';
import {useLocation} from 'react-router-dom';

function Layout() {
    const cookie = window.localStorage.getItem('showCookieAlert');
    const [scrollTop, setScrollTop] = useState(false);
    const [showCookieAlert, setShowCookieAlert] = useState(!cookie);
    const windowHeight = window.innerHeight;

    let location = useLocation();

    useEffect(
        () => {
            const widget = document.getElementById('widget-btn');
            if(widget) widget.remove();
        },
        [location]
    );

    return (
        <div className={style.layout}>
            <Waypoint
                topOffset={-windowHeight / 1.5}
                bottomOffset={100000}
                onEnter={(e) => {
                    setScrollTop(true);
                }}
                onLeave={(e) => {
                    setScrollTop(false);
                }}
            />

            {scrollTop && <ScrollTop/>}
            {showCookieAlert && (
                <CookieAlert close={() => {
                    setShowCookieAlert(false);
                    window.localStorage.setItem('showCookieAlert', false);
                }}/>
            )}

            <div className={style.content}>
                <Outlet/>
            </div>
            <Footer/>
        </div>
    );
}

export default Layout;

import React from 'react';
import style from './CookieAlert.module.css';
import CloseIcon from '@mui/icons-material/Close';

function CookieAlert(props) {
    const doClose = props.close;

    return (
        <div className={style.cookieAlert}>
            <div className={style.cookieAlertWrapper}>
                <p>
                    This website uses cookies to provide you with a more responsive and personalized experience
                </p>
                <div className={style.cookieAlertActions}>
                    <a href={'https://grem.capital/cookie'} target={'_blank'}>
                        Read
                    </a>
                    <button onClick={() => {
                        doClose();
                    }}>
                        Accept and close
                    </button>
                </div>
                <CloseIcon className={style.cookieAlertClose} onClick={() => {
                    doClose();
                }}/>
            </div>
        </div>
    );
}

export default CookieAlert;
